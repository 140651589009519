<script setup lang="ts">

const {companyName, companyLogo, withoutWelcomeTo, hasBorder,borderColorClass , hasDescription, hasShadow} = defineProps(['companyName', 'companyLogo', 'withoutWelcomeTo','hasShadow','hasBorder','borderColorClass','hasDescription'])
</script>

<template>
  <div >
    <div class="mb-2">
      <img v-if="companyLogo" :src="companyLogo" alt="" :class="['mx-auto h-24 z-10', hasBorder? 'rounded-full border-2':'',hasShadow? 'shadow-[rgba(0,_0,_0,_0.4)_0px_5px_20px]':'', hasBorder && borderColorClass ? `${borderColorClass}`:'']"/>
      <img v-else src="../../assets/icon/app.png" alt="" :class="['mx-auto h-16 md:h-20 z-10', hasBorder? 'rounded-full border-2':'',hasShadow? 'shadow-[rgba(0,_0,_0,_0.4)_0px_5px_20px]':'', hasBorder && borderColorClass ? `${borderColorClass}`:'']"/>
    </div>
    <div class="text-center mb-4 flex flex-col" v-if="hasDescription">
      <span v-if="!withoutWelcomeTo" class="text-sm text-gray-700">{{ ('welcomeTo') }}</span>
      <span class="text-xl font-semibold text-gray-900">{{ companyName }}</span>
    </div>
  </div>
</template>

<style scoped>

</style>
